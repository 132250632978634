var scrollTop;
var elementOffset;
$(function(){
	$('header .burger-btn').click(function(){
		$('body').toggleClass('show-mobile-nav');
	});

	$('.responsive-nav-overlay').click(function(){
		$('body').removeClass('show-mobile-nav');
	});
});

$( window ).on('resize', function() {
	"use strict";
	if($('.navigation').offset() !== undefined) {
		//var navInitDistance = $('.navigation').offset().top;
		var threshold = $('header').height();

		if($('body').hasClass('section-global-positioning')){
			$('.navigation').addClass('fixed');
			$('.navigation').css('top',threshold);

			var h = $('header').height() + $('.navigation').height();
			var adjust = $('.map').height() - (h+$('footer').height());
			$('.map').css('margin-top', h);
			$('.map').css('height',adjust);

		 }
	}
});
$(window).load( function(){
	"use strict";
	if($(document).scrollTop() > 50){
		$('header').addClass('sticky');
	}else{
		$('header').removeClass('sticky');
	}
	setTimeout(function(){

		var threshold = $('header').height();
		var scrollpoint = $(window).innerHeight() / 3;

		if($('.navigation').offset() !== undefined) {
			if($('body').hasClass('section-global-positioning')){
				$('.navigation').addClass('fixed');
				$('.navigation').css('top',threshold);

				var h = $('header').height() + $('.navigation').height();
				var adjust = $('.map').height() - (h+$('footer').height());
				console.log($('footer').height());
				$('.map').css('margin-top', h);
				$('.map').css('height',adjust);
			 }
		}

		$(window).scroll(function(){

			if($(document).scrollTop() > scrollpoint){
					$('header').addClass('sticky');
					$('.wrap').css('margin-top',threshold+'px');
					$('.to-top').fadeIn();
					if(!$('.newsletter-trigger').hasClass('inline-btn') ){
						$('.newsletter-trigger').fadeOut();
					}
					
					$('.availability-cta').fadeIn();
			}else{
					$('header').removeClass('sticky');
					$('.wrap').css('margin-top','0');
					$('.to-top').fadeOut();
					if(!$('.newsletter-trigger').hasClass('inline-btn') ){
						$('.newsletter-trigger').fadeIn();
					}
					$('.availability-cta').fadeOut();
			}

			scrollTop     = $(window).scrollTop();
			elementOffset = $('.heros').innerHeight() - $('header').innerHeight();

			if(scrollTop >= elementOffset){
				$('.navigation').addClass('fixed');
			}else{
				$('.navigation').removeClass('fixed');
			}
		});

	},500);
});
