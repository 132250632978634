function initMyInfoFilter(){
	"use strict";
	var current_tab = $('.filter_nav li.active a').attr('href');
	$(current_tab).addClass('active');
	$(current_tab).addClass('in');
	
	$('.filter_nav li a').click(function() {
		var current_tab = $(this).data('title');
		var href =  $(this).data('href');
		$('.hero-headline').html(current_tab);

		var stateObj = { title:current_tab};
    	history.pushState(stateObj, current_tab , href);
	});


	
}