var  $body, $modal, $secondaryModal, $window,sidebar_pos, sidebar_width,ajax_contents,scroll_from_top,menu_scroll;
var timeout = false;
var rtime;
var delta = 500;


$(window).resize(function() {
  rtime = new Date();
  if (timeout === false) {
    timeout = true;
    setTimeout(resizeend, delta);
  }
});

function resizeend() {
  if (new Date() - rtime < delta) {
    setTimeout(resizeend, delta);
  } else {
    timeout = false;
  }
}

$(window).load( function(){
	 if (window.location.hash) {
      var anchorpage = location.hash.split('#');
	  var offsetTop = $('#'+anchorpage[1]).offset().top-150;
		 $("html, body").animate({  
			scrollTop: offsetTop
		}, 1000); 
    }
}); 

$(function(){ 
	"use strict";   
    $('select.select2').select2(); 
 
  $('.daterange').daterangepicker({
	  locale: {
	      format: 'DD/MM/YYYY'
	    }
	}, function(start, end, label) {

		
	});

    $('.daterange').on('click', function(ev) {
    	if($('.drp-buttons .drp-notice').length==0 ){
    		$('.drp-buttons').prepend('<span class="drp-notice">Charters run Saturday to Saturday</span>');
    	}
	
  });

	setTimeout(function () {
		$('body').removeClass("loading");
		$('body').addClass('loaded');	
	}, 1200);	
	
	
	if ("ontouchstart" in window || navigator.msMaxTouchPoints) {
		$('body').addClass('touch');		
	} else {
		$('body').addClass('no-touch');
	}
	
	$('.scroll-down').click(function(e){
		$("html, body").animate({ scrollTop: $('#hero_section').innerHeight() }, 500);	
		e.preventDefault();
	});

	 $.each($('.button_wrap .button_content'), function(){
        $(this).hover(
            function () {
                if($('html').hasClass('touch')){
                    return false;
                }
                $(this).stop(true, false).animate({
                    width: 200
                }, 500);
            },
            function () {
                if($('html').hasClass('touch')){
                    return false;
                }
                $(this).stop(true, false).animate({
                    width: 50
                }, 500);
            }
        );
		
		$('.button_call.button_content').unbind('mouseenter').unbind('mouseleave');
    });



	$(document).on("click", ".burger", function(e) {
	   $("body").toggleClass('show-responsive');
	   e.preventDefault();
    }); 
	$(document).on("click", ".responsive-nav-overlay", function(e) {
	   $("body").removeClass('show-responsive');
	   e.preventDefault();
    });
	
	$(document).on("click", ".slide-to", function(e) {
        e.preventDefault(); 
        var offsetTop = 0;        
        if($(this).attr('href')!='#wrap'){        	
        	offsetTop = $($(this).attr('href')).offset().top - $('header').innerHeight();
        }         
        $("html, body").animate({  
			scrollTop: offsetTop
		}, 1000);  
    });


    $(document).on("click", ".navigation-tel", function(e) {
        fbq('trackCustom', 'NavigationContactPhone'); 
    });


     $(document).on('click', ".ajax-modal", function (e) {

        try{
            e.preventDefault();
            openAjaxModal($(this).attr('href'));
                $('.modal').on('shown.bs.modal', function() {
                    $(document).off('focusin.modal');
                });
    
        }catch(Error){
            console.log(Error);
        }

        return false;
    });

	

	
     $(document).on("click", ".logo", function(e) {
		if( $("body").hasClass('section-home')){
			e.preventDefault();		 
			$("html, body").animate({ 
				scrollTop: 0
			}, 1000);	
		}
    });



     $(document).on("click", ".responsive-nav .page_link", function(e) {
			$('.responsive-nav .pages-group').removeClass('opened');
	   });
	
	$(".top-menu").addClass('visible');
	
	var waypoints = $('.way-point-animate').waypoint(function(direction) {
        $(this.element).addClass('shown');
    }, {
        offset: '90%'
    });
	var waypoints = $('.way-point-animate-left').waypoint(function(direction) {
        $(this.element).addClass('shown');
    }, {
        offset: '90%'
    });
	var waypoints = $('.way-point-animate-right').waypoint(function(direction) {
        $(this.element).addClass('shown');
    }, {
        offset: '90%'
    });
	
    callbacks();
	$body = $('body');
    $body.append('<div class="modal fade" id="modalSecondary" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"></div>');
    $body.append('<div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"></div>');
    $modal = $('#modal');
});

function equalizeHeight(target){
	"use strict";
    var min_height = 0;
    setTimeout(function(){
        $(target).each(function(){
            $(this).css('min-height',0);
            if($(this).outerHeight() > min_height){
                min_height = $(this).outerHeight();
            }
        });
        if(min_height>0){
            $(target).css('min-height',min_height);
        }
    },100);
}

function initShares(){
	"use strict";
	$('.close-share-this').click(function(){
		$('.modal.in').modal('hide');
		return false;
	});
	
	var clipboard = new Clipboard('.clipboardButton');
	clipboard.on('success', function(e) {
		console.info('Action:', e.action);
		console.info('Text:', e.text);
		console.info('Trigger:', e.trigger);
		
		$('.clipboardButton .copy-label').text(' copied!');
		
		e.clearSelection();
	});
	clipboard.on('error', function(e) {
		console.error('Action:', e.action);
		console.error('Trigger:', e.trigger);
		
	});	
	
}


function openAjaxModal(href,$mm){

    $m = $mm || $modal;

    $m.removeData('bs.modal');
    $m.empty();

    $m.load(href,function(){
        $m.modal('show');
        //skinFormElements('#'+$m.attr('id'));
        callbacks('#'+$m.attr('id'));
        try {
            FB.XFBML.parse();
            twttr.widgets.load();
        } catch (ex) {
            console.log(ex);
        }
    });
}

function callbacks(c) {
	"use strict";
    var container = c || '';

     
    $(container + ' .callbacks').each(function () {
        if(!$(this).hasClass('callbacks-executed')){
            if(container !=='' || !$(this).hasClass('callbacks-executed')){
                var c = $(this).data();
                for (var i in c) {
                    if (i.indexOf('fn') === 0 && c[i] !== '') {
                        var fn = c[i];
                        var params = c[ 'params' + i ] || null;

                        try {
                            if (c[ 'after' + i ] && typeof(window[c[ 'after' + i ]]) === 'function') {

                                var callbackAfter = c[ 'after' + i ];
                                var callbackAfterParams = c[ 'after' + i + 'Params' ] || null;

                                window[ fn ](params, function(){
                                    window[callbackAfter](callbackAfterParams);
                                });
                            } else {
                                window[ fn ](params);
                            }
							$(this).addClass('callbacks-executed');
                        } catch (Error) {
                            console.log(Error + ' '+fn);
                        }
                    }
                }
            }
        }
    });
}