function carouselTestimonials(){
    "use strict";
    var carousel = $(".testimonials-list");
    carousel.slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear'
		
    });	
}