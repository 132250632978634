function FleetFilters(){
	"use strict";
	$('.vessel-filters a').click(function(e){
		
		var $this = $(this);
		$('.vessel-filters a').removeClass('active');
		$this.addClass('active');
		var type = $this.data('type');
		$('.vessel-filters-dropdown option').removeAttr("selected");
		$('.vessel-filters-dropdown option[data-type="'+type+'"]').attr('selected', 'selected');
		
		if(type==='all'){
			$('.fleets-grid .fleet.hidden').removeClass('hidden');
			$('.fleets-overview-grid .fleet.hidden').removeClass('hidden');
		}else{
			$('.fleets-grid .fleet').addClass('hidden');
			$('.fleets-overview-grid .fleet').addClass('hidden');
			$('.fleets-grid .fleet.type-'+type).removeClass('hidden');
			$('.fleets-overview-grid .fleet.type-'+type).removeClass('hidden');
		}
		return false;
	});
}
function FleetSelectFilters(){
	"use strict";
		var $this = $('option:selected');
		var type = $this.data('type');
		$('.vessel-filters .nav-item a').removeClass('active');
		$('.vessel-filters .nav-item a[data-type="'+type+'"]').addClass('active');
		if(type==='all'){
			$('.fleets-grid .fleet.hidden').removeClass('hidden');
			$('.fleets-overview-grid .fleet.hidden').removeClass('hidden');
		}else{
			$('.fleets-grid .fleet').addClass('hidden');
			$('.fleets-overview-grid .fleet').addClass('hidden');
			$('.fleets-grid .fleet.type-'+type).removeClass('hidden');
			$('.fleets-overview-grid .fleet.type-'+type).removeClass('hidden');
		}
}
function FleetGallery(){
	"use strict";
	$('.fleet-header').click(function(){
		$(this).toggleClass('active');
		setTimeout(function () {
			$('.fleet-gallery').each(function(){
				$(this).slick('refresh');
			});
			
			$(window).trigger('resize');
		},500);
	});
	$('.fleet-gallery').slick({
		  infinite: false,
		  slidesToShow: 1,
		  slidesToScroll: 1,
		  cssEase: 'linear',
		  dots: false,
		  speed: 500,
		  nextArrow:'<a class="next-slide slide-arrow" href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
		  prevArrow:'<a class="prev-slide slide-arrow" href="#"><i class="fa fa-angle-left" aria-hidden="true"></i></i></a>',
			responsive: [
			{
			  breakpoint: 767,
			  settings: {
				arrows:false,
				dots:false,
			  }
			}
		  ]
	});
}
function initNumberAnimations(){	
	"use strict";
	var waypoints = $('.numAnimate').waypoint(function(direction) {
		$(this.element).addClass('shown');
		$('.numAnimate.shown').each(function(){
			if(!$(this).hasClass('animated')){
				$(this).prop('Counter',0).animate({
				Counter: parseInt($(this).text())
			}, {
				duration: 5000,
				easing: 'swing',
				step: function (now) {
					$(this).text(Math.ceil(now).toLocaleString());
				}
    		});
				
				$(this).addClass('animated');
			}
		});		
	}, {
		offset: '90%'
	});
	
}


function loadMap(){
    $.ajax({
        url: '//maps.googleapis.com/maps/api/js?key=AIzaSyAPyGu0Ic6jqnDfIlKJWn3jjX2tJxTIcPE',
        dataType: "script",
        success:function(){
            if(typeof(google)!=='undefined'){
				var map_styling = [{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [{
						"color": "#444444"
					}]
				}, {
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [{
						"color": "#fcfcfc"
					}]
				}, {
					"featureType": "poi",
					"elementType": "all",
					"stylers": [{
						"visibility": "off"
					}]
				}, {
					"featureType": "road",
					"elementType": "all",
					"stylers": [{
						//"saturation": -100
						"visibility": "off"
					}, {
						"lightness": 45
					}]
				}, {
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [{
						"visibility": "off"
						//"visibility": "simplified"
					}]
				}, {
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [{
						"visibility": "off"
					}]
				}, {
					"featureType": "transit",
					"elementType": "all",
					"stylers": [{
						"visibility": "off"
					}]
				}, {
					"featureType": "water",
					"elementType": "all",
					"stylers": [{
						"color": "#254169"
					}, {
						"visibility": "on"
					}]
				}];
                var myLatlng = new google.maps.LatLng(0,0);
                var options = {
                    zoom:1,
					minZoom: 3,
                    center: myLatlng,
					styles:map_styling,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                var map = new google.maps.Map($('#map_canvas')[0], options);
				var markerImage = new google.maps.MarkerImage(
				SHIP_MARKER
			);

				var allowedBounds = new google.maps.LatLngBounds(
					new google.maps.LatLng(85, -180),	// top left corner of map
					new google.maps.LatLng(-85, 180)	// bottom right corner
				);

				var k = 5.0; 
				var n = allowedBounds .getNorthEast().lat() - k;
				var e = allowedBounds .getNorthEast().lng() - k;
				var s = allowedBounds .getSouthWest().lat() + k;
				var w = allowedBounds .getSouthWest().lng() + k;
				var neNew = new google.maps.LatLng( n, e );
				var swNew = new google.maps.LatLng( s, w );
				boundsNew = new google.maps.LatLngBounds( swNew, neNew );
				map .fitBounds(boundsNew);
				
				  var southWest = new google.maps.LatLng(0, 0);
				  var northEast = new google.maps.LatLng(34.052234, -118.243685);
				  var lngSpan = northEast.lng() - southWest.lng();
				   var latSpan = northEast.lat() - southWest.lat();
				
				
				
				var markers = [];
				
				 for (var i = 1; i < 71; i++) {

					var location = new google.maps.LatLng(southWest.lat() + latSpan * Math.random(), southWest.lng() + lngSpan * Math.random());

					var marker = new google.maps.Marker({
						position: location,
						icon: markerImage,
						map: map
					});

					markers.push(marker);
				}
				
				
			
            }

        }
		
    });
	
}