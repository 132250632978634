var map_styling = [
    {
		featureType: "poi",
		elementType: "labels",
				stylers: [{
					visibility: "off"
				}]
			}, 
			{ 
				featureType: "road.local", 
				elementType: "labels.icon", 
				stylers: [{ 
					"visibility": "off" 
				}] 
			},
			{ 
				featureType: "road.arterial", 
				elementType: "labels.icon", 
				stylers: [{ 
					"visibility": "off" 
				}] 
			},
			{
				featureType: "road",
				elementType: "geometry.stroke",
				stylers: [{
					visibility: "off"
				}]
			}, 
			{
				elementType: "geometry",
				stylers: [{
					saturation: -100
				}]
			},
			{
				elementType: "labels",
				stylers: [{
				saturation: -100
				}]
			}, 
			{
				featureType: "poi",
				elementType: "geometry.fill",
				stylers: [{
					color: "#ffffff"
				}]
			},
			{
				featureType: "landscape",
				stylers: [{
					color: "#ffffff"
				}]
			}, 
			{
				featureType: "road",
				elementType: "geometry.fill",
				stylers: [ {
					color: "#f1f1f1"
				}]
			}, 
			{
				featureType: "water",
				elementType: "geometry",
				stylers: [{
					color: "#0066b3"
				}]
			}
];

var map_styling_2 = [
    {
		featureType: "poi",
		elementType: "labels",
				stylers: [{
					visibility: "off"
				}]
			}, 
			{ 
				featureType: "road.local", 
				elementType: "labels.icon", 
				stylers: [{ 
					"visibility": "off" 
				}] 
			},
			{ 
				featureType: "road.arterial", 
				elementType: "labels.icon", 
				stylers: [{ 
					"visibility": "off" 
				}] 
			},
			{
				featureType: "road",
				elementType: "geometry.stroke",
				stylers: [{
					visibility: "off"
				}]
			}, 
			{
				elementType: "geometry",
				stylers: [{
					saturation: -100
				}]
			},

  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        "visibility": "off"
      }
    ]
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        "visibility": "off"
      }
    ]
  },
			{
				elementType: "labels",
				stylers: [{
				visibility: "off",
				saturation: -100
				}]
			}, 
			{
				featureType: "poi",
				elementType: "geometry.fill",
				stylers: [{
					color: "#dbdcdd"
				}]
			},
			{
				featureType: "landscape",
				stylers: [{
					color: "#dbdcdd"
				}]
			}, 
			{
				featureType: "road",
				elementType: "geometry.fill",
				stylers: [ {
					color: "#dbdcdd"
				}]
			}, 
			{
				featureType: "water",
				elementType: "geometry",
				stylers: [{
					color: "#ffffff",

				}]
			}
];

function initContactForm() {

    $('.contact-form').validate({
        submitHandler: function() {
            var data = $('.contact-form').serialize();
            var object = {
                'success': function (response){
                    $('.contact-form .form-response').removeClass('alert-success');
                    $('.contact-form .form-response').removeClass('alert-danger');

                    if(response['status']==='ok'){
                        $('.contact-form .form-response').addClass('alert-success').show().html(response['message']);
                         setTimeout(function () {
                        	window.location =  URL+'contact/' ;
                		}, 2000);

                    }else{
                        $('.contact-form .form-response').addClass('alert-danger').show().html(response['error']);
                    }
                },
                'data' : data
            };
            $.ajax(object);
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}

var overlay;
var timeout = false;
var rtime;
var delta = 500;


var w = window.innerWidth;


function CustomMarker(opts) {
    this.setValues(opts);
}

$(window).resize(function() {
	 w = window.innerWidth;
  rtime = new Date();
  if (timeout === false) {
    timeout = true;
    setTimeout(resizemaps, delta);
  }
});

function resizemaps() {
  if (new Date() - rtime < delta) {
  	 console.log(0);
    setTimeout(resizemaps, delta);
  } else {
    timeout = false;
  }
}



//MAP_MARKER
function setMap(target){

		if (typeof $(target+' .map-container-contact')[0] !== "undefined") {

			    var t_zoom= 13;

			    if(typeof  $(target).data('zoom')!==('undefined')  ){
			    	if(w>767){
			    		t_zoom =  $(target).data('zoom');
			    	}else{
			    		t_zoom =  5.5;
			    	}
			    	
			    }
			    var map_style =map_styling;
			    if(target==='#bases-map-contact'){
			    	map_style =map_styling_2;
			    }
				
				var mapCenterlat = 37.9048011;
				var mapCenterlng = 23.7289501;
				if(window.location.href == URL+'base/'){
			   		 mapCenterlat = 37.8771153;
					 mapCenterlng = 23.7273146;
				}
				
				var infowindow = new google.maps.InfoWindow(
				{
					pixelOffset: new google.maps.Size(0,-50)
				}); /* SINGLE */
				var options = {
					 zoom:t_zoom,
					 scrollwheel: false,
					 styles:map_style,
				     navigationControl: false,
				     draggable: false,
					 center: {lat: mapCenterlat, lng: mapCenterlng},
					 fullscreenControl:false,
					 streetViewControl:false,
					 mapTypeControl:false,
					 zoomControl:(target==='#bases-map-contact')?false:true
			 };
				map = new google.maps.Map($(target+' .map-container-contact')[0], options);
				var markers = [];
				

				$(target+' .contact-info').each(function(){
					   var $this = $(this);
						var markerImage = new google.maps.MarkerImage(
								MAP_MARKER
						);
						var title = $this.find('.title').html();
						var latLng = new google.maps.LatLng( $this.data('lat') , $this.data('lng'));
						var marker = new CustomMarker({
								position : latLng,
								map      : map,
								icon	 : markerImage,
								title	 : title,
								pin_class:$this.data('class'),
								pin_anchor:"#"+$this.data('class')
								
						});

						var phone='';
						var email = '';

						
						if($this.find('.phone').length>0){
							 phone = '<div><a href="tel:'+$this.find('.phone')+'">' + $this.find('.phone').html()+ '</a></div>';
						}
						if($this.find('.mail').length>0){
							email = '<div><a href="mailto:'+$this.find('.mail')+'">' + $this.find('.mail').html()+ '</a></div>';
						}
						var contentString='';
						if($this.data('class')=='alimos'){
							 contentString =
							'<div class="maps_infobox">'+
							 '<div class="siteNotice">'+$this.find('.title').html() +'</div>'+
							 '<div class="bodyContent">'+phone+email+
							 '</div>'+
							'</div>';
						}else{
							 contentString =
							'<div class="maps_infobox">'+
							 '<div class="siteNotice">'+$this.find('.title').html() +'</div>'+
							 '<div class="bodyContent">'+
								'<div>' + $this.find('.address').html()+ '</div>'+
								phone+email+
								 '<a target="_blank" href="'+$this.data('directions')+'">View Directions</a>'
							 '</div>'+
							'</div>';
						}
						
						


						
						if(target=='#bases-map-contact'){
							google.maps.event.addListener(marker, 'click', function(){

								var offsetTop = $('#bases-'+this.pin_class).offset().top-150;
								 $("html, body").animate({  
									scrollTop: offsetTop
								}, 1000); 
							});
							
						}else{

							if(window.location.href == URL+'contact/'){
								if((marker.title) === 'Main Office'){
									infowindow.setContent( contentString );
									infowindow.open(map, marker);
								}
							}else{
								infowindow.setContent( contentString );
								infowindow.open(map, marker);
							}
							google.maps.event.addListener(marker, 'click', function(){
								 infowindow.close();
								 infowindow.setContent( contentString );
								 infowindow.open(map, marker);
							});

						}
				});
				 
		}
}


function initContactMap( target ){
		"use strict";	

		CustomMarker.prototype = new google.maps.OverlayView();
			CustomMarker.prototype.draw = function() {
				var self = this;
				var div = this.div;
				if (!div) {

					if(target==='#bases-map-contact'){						
						div = this.div = $('' +
						'<a class="pin-anchor-link '+this.pin_class+'" href="#bases-'+this.pin_class+'">' +
						'<div class="pin-line"></div>' +
						'<div class="pin-name">'+this.pin_class+'</div>' +
						'<div class="pin-wrap">' +
						'<div class="pin"></div>' +
						'</div>' +
						'</a>' +
						'')[0];
					}else{
						div = this.div = $('' +
						'<div>' +
						'<div class="shadow"></div>' +
						'<div class="pulse"></div>' +
						'<div class="pin-wrap">' +
						'<div class="pin"></div>' +
						'</div>' +
						'</div>' +
						'')[0];
					}
				
					this.pinWrap = this.div.getElementsByClassName('pin-wrap');
					this.pin = this.div.getElementsByClassName('pin');
					this.pinShadow = this.div.getElementsByClassName('shadow');
					div.style.position = 'absolute';
					div.style.cursor = 'pointer';
					var panes = this.getPanes();
					panes.overlayImage.appendChild(div);
					google.maps.event.addDomListener(div, "click", function(event) {
						google.maps.event.trigger(self, "click", event);
					});
				}
				var point = this.getProjection().fromLatLngToDivPixel(this.position);
				if (point) {
					div.style.left = point.x + 'px';
					div.style.top = point.y + 'px';
				}
			};

			setMap(target);
							
}

function setMAp2(target){

		if (typeof $(target+' .map-container-contact-2')[0] !== "undefined") {

				var mapCenterlat = $(target+' .contact-info').data('lat');

				var mapCenterlng =  $(target+' .contact-info').data('lng');
			
				var infowindow = new google.maps.InfoWindow(
				{
					pixelOffset: new google.maps.Size(0,-50)
				}); /* SINGLE */
				var options = {
					 zoom:13,
					 scrollwheel: false,
					 styles:map_styling,
					 center: {lat: mapCenterlat, lng: mapCenterlng},
					 fullscreenControl:false,
					  navigationControl: false,
				     draggable: false,
					 streetViewControl:false,
					 mapTypeControl:false,
					 zoomControl:true
			 };
				map = new google.maps.Map($(target+' .map-container-contact-2')[0], options);
				var markers = [];
				

				$(target+' .contact-info').each(function(){
					    var $this = $(this);
						var markerImage = new google.maps.MarkerImage(
								MAP_MARKER
						);
						var title = $this.find('.title').html();
						var latLng = new google.maps.LatLng( $this.data('lat') , $this.data('lng'));
						var marker = new CustomMarker({
								position : latLng,
								map      : map,
								icon	 : markerImage,
								title	 : title
								
						});
						var phone='';
						var email = '';
						
						if($this.find('.phone').length>0){
							 phone = '<div><a href="tel:'+$this.find('.phone')+'">' + $this.find('.phone').html()+ '</a></div>';
						}
						if($this.find('.mail').length>0){
							email = '<div><a href="mailto:'+$this.find('.mail')+'">' + $this.find('.mail').html()+ '</a></div>';
						}


						var contentString='<div class="maps_infobox">'+
						 '<div class="siteNotice">'+$this.find('.title').html() +'</div>'+
						 '<div class="bodyContent">'+
							'<div>' + $this.find('.address').html()+ '</div>'+
							phone+email+
							 '<a target="_blank" href="'+$this.data('directions')+'">View Directions</a>'
						 '</div>'+
						'</div>';

						

						infowindow.setContent( contentString );
						infowindow.open(map, marker);
						
						google.maps.event.addListener(marker, 'click', function(){
							 infowindow.close();
							 infowindow.setContent( contentString );
							 infowindow.open(map, marker);
						});
				});

				 
		}
}
function initContactMap2( target){
		"use strict";	
		CustomMarker.prototype = new google.maps.OverlayView();
			CustomMarker.prototype.draw = function() {
				var self = this;
				var div = this.div;
				if (!div) {
					div = this.div = $('' +
						'<div>' +
						'<div class="shadow"></div>' +
						'<div class="pulse"></div>' +
						'<div class="pin-wrap">' +
						'<div class="pin"></div>' +
						'</div>' +
						'</div>' +
						'')[0];
					this.pinWrap = this.div.getElementsByClassName('pin-wrap');
					this.pin = this.div.getElementsByClassName('pin');
					this.pinShadow = this.div.getElementsByClassName('shadow');
					div.style.position = 'absolute';
					div.style.cursor = 'pointer';
					var panes = this.getPanes();
					panes.overlayImage.appendChild(div);
					google.maps.event.addDomListener(div, "click", function(event) {
						google.maps.event.trigger(self, "click", event);
					});
				}
				var point = this.getProjection().fromLatLngToDivPixel(this.position);
				if (point) {
					div.style.left = point.x + 'px';
					div.style.top = point.y + 'px';
				}
			};

			setMAp2(target);				
}

