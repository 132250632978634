// JavaScript Document

var keydown_timeout;
var keydown_xhr;
function initNewsletterForm() {
	"use strict";
    $('form[name="newsletter-form"]').validate({
				focusCleanup: true,
        submitHandler: function () {

					
            $.ajax({data: $('form[name="newsletter-form"]').serialize(), success: function (data) {
						$('#email-success').remove();
						$('#email-error').remove();

            if (data.status === "ok") {
								$('form[name="newsletter-form"] .input-element').append('<label id="email-success" class="success" for="email">'+ data.message+'</label>');
	          } else if (data.status === 'error') {
                $('form[name="newsletter-form"] .input-element').append('<label id="email-error" class="error" for="email">'+ data.message+'</label>');
            } else {
								$('form[name="newsletter-form"] .input-element').append('<label id="email-error" class="error" for="email">An unknown error occurred. Please try again later</label>');
	          }
	        }});


        }
    });
}
function contactForm() {
	 var validateEmail = function(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    $('.send').hover(function(){
        if ($('#email_input').val() == '') {
            $(this).addClass('invalid');
            $('.cross').text(lang.check_email);
            $('#email_input').addClass('invalid');
        }
        if ($("#name_input").val() == '') {
            $(this).addClass('invalid');
            $('.cross').text(lang.check_name);
            $('#name_input').addClass('invalid');
        }
    }, function(){
		$('.invalid').removeClass('invalid');
		$('.send').removeClass("shake_it_baby");
		$('.send').removeClass("highlighted");
		$('.envelope').fadeIn();
	});

	$('.send').click(function(e){
    	e.preventDefault();
		if (!$(this).hasClass('invalid')) {
			$('.send').addClass('sending');
			// wishlist to send
			var name = $("#name_input").val();
			var email = $('#email_input').val();
			var lang = $("#language_input").val() || 'ru';

			var ajaxurl = 'mail.php?name=' + name + '&email=' + email + '&lang=' + lang;


			$.ajax({
				data: $('form[name="contact-form"]').serialize(),
				success: function (data) {
                if (data.status == 'ok') {

                    $('.send').addClass('success');
					setTimeout(function() {
						$('.send').removeClass('sending').removeClass('success');
						$('#email_input').val('');
					}, 2000);

                } else if (data.status == 'error') {
                    form_submit_response('form[name="contact-form"]', 'error', data.error);
                } else {
                    form_submit_response('form[name="contact-form"]', 'error', 'An unknown error occurred. Please try again later');
                }
            }});
		} else {
			$('.send').addClass("highlighted");
			$('.envelope').hide();
			$('.send').addClass("shake_it_baby");
			$('.invalid').addClass("highlighted");
			setTimeout(function(){
				$('input.highlighted').removeClass("highlighted");

			}, 1500);
		}
	});


    $.ajax({
        url: 'https://www.google.com/recaptcha/api.js',
        dataType: "script"
    });

    $('form[name="contact-form"]').validate({

        submitHandler: function () {
            $.ajax({data: $('form[name="contact-form"]').serialize(), success: function (data) {
                if (data.status == 'ok') {

                    ga('send', 'pageview', '/contact-success.html');

                    form_submit_response('form[name="contact-form"]', 'success', data.message, true);
                } else if (data.status == 'error') {
                    form_submit_response('form[name="contact-form"]', 'error', data.error);
                } else {
                    form_submit_response('form[name="contact-form"]', 'error', 'An unknown error occurred. Please try again later');
                }
            }});
        }
    });
}


function getHash() {
    var hash = window.location.hash;
    return hash.substring(2); // remove #!
}


function loginForm() {

    $('form[name="login_form"]').addClass('ready');
    $('form[name="login_form"]').validate({
        rules: {
            'email': 'required',
            'password': 'required'
        },
        messages: {
            'email': 'Required',
            'password': 'Required'
        },
        submitHandler: function () {
            $.ajax({data: $('form[name="login_form"]').serialize(), success: function (data) {
                if (data.status == 'ok'  && typeof(data.redirect) !== 'undefined') {
                    var hash = getHash();
                    window.location = data.redirect;
                 
                    if(registerTimeout!=null){
                        clearTimeout(registerTimeout);
                    }
                }else if (data.status == 'ok'){
                    window.location.reload();
                    if(registerTimeout!=null){
                        clearTimeout(registerTimeout);
                    }
                }else if (data.status == 'error') {
                    if (typeof(data.callback) !== 'undefined') {
                        var email = $('form[name="login_form"] input[name="email"]').val();
                        eval(data.callback + '("' + data.error + '","' + email + '")');
                    } else {
                        form_submit_response('form[name="login_form"]', 'error', data.error);
                    }
                } else {
                    form_submit_response('form[name="login_form"]', 'error', 'An unknown error occurred. Please try again later');
                }

            }});
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}

function registerForm() {
    var target = 'form[name="register_form"]';
    $(target).validate({
        submitHandler: function ($e) {
            $.ajax({data: $(target).serialize(), success: function (data) {
                if (data.status == 'ok') {
                    if(data.redirect){
                        window.location = data.redirect;
                    }else{
                        form_submit_response(target, 'success', data.message, true);
                    }
                    form.closest('.modal').modal('hide');
                } else if (data.status == 'error') {
                    form_submit_response(target, 'error', data.error);
                } else {
                    form_submit_response(target, 'error', 'An unknown error occurred. Please try again later');
                }
            }});
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}


function updateDetailsForm() {
    var target = 'form[name="update_profile_form"]';
    $(target).validate({
        
        submitHandler: function ($e) {
            $.ajax({data: $(target).serialize(), success: function (data) {
                if (data.status == 'ok') {
                    if(data.redirect){
                        window.location = data.redirect;
                    }else{
                        form_submit_response(target, 'success', data.message, true);
                    }
                } else if (data.status == 'error') {
                    form_submit_response(target, 'error', data.error);
                } else {
                    form_submit_response(target, 'error', 'An unknown error occurred. Please try again later');
                }
            }});
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}



function registerCodeForm() {
    var target = 'form[name="register_code_form"]';
    $(target).validate({
        rules: {
            'registration_code': 'required'
        },
        submitHandler: function ($e) {
            $.ajax({data: $(target).serialize(), success: function (data) {
                if (data.status == 'ok') {
                    if(data.redirect){
                        window.location = data.redirect;
                    }else{
                        form_submit_response(target, 'success', data.message, true);
                    }
                    form.closest('.modal').modal('hide');
                } else if (data.status == 'error') {
                    form_submit_response(target, 'error', data.error);
                } else {
                    form_submit_response(target, 'error', 'An unknown error occurred. Please try again later');
                }
            }});
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}

function updateAccountForm() {

    $('form[name="my_account_form"]').addClass('ready');
    $('form[name="my_account_form"]').validate({
        rules: {
            'name': 'required',
            'surname': 'required',
            'username': 'required',
            'password': {
                minlength: 5
            },
            'password_verify': {
                minlength: 5,
                equalTo: "#password"
            },
        },
        messages: {
            'name': 'Required',
            'surname': 'Required',
            'username': 'Required',
            'password': {
                minlength: "! Must be at least 5 characters long"
            },
            'password_verify': {
                required: 'Required',
                minlength: "! Must be at least 5 characters long"
            },
        },
        submitHandler: function () {
            $.ajax({data: $('form[name="my_account_form"]').serialize(), success: function (data) {
                if (data.status == 'ok') {
                    form_submit_response('form[name="my_account_form"]', 'success', 'Account details have been succesfully updated', true);
                } else if (data.status == 'error') {
                    form_submit_response('form[name="my_account_form"]', 'error', data.error);
                } else {
                    form_submit_response('form[name="my_account_form"]', 'error', 'An unknown error occurred. Please try again later');
                }
            }});
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}


function resend_activation_email(message, email) {
    message += '\nResend activation email?';
    var r = confirm(message);
    if (r == true) {
        $.ajax({data: {module: 'customers', 'action': 'send-activation-email', email: email}, success: function (data) {
            if (data.status == 'ok') {
                form_submit_response('', 'success', 'Activation email succesfully sent', true);
            } else if (data.status == 'error') {
                form_submit_response('form[name="login_form"]', 'error', data.error);
            } else {
                form_submit_response('form[name="login_form"]', 'error', 'An unknown error occurred. Please try again later');
            }

        }});
    }


}

function forgotPasswordForm() {
    $('form[name="forgot_password_form"]').addClass('ready');
    $('form[name="forgot_password_form"]').validate({
        rules: {
            'email': 'required'
        },
        messages: {
            'email': 'Required'
        },
        submitHandler: function () {
            $('body').prepend('<div class="loading blocking text-center"><span>VALIDATING REQUEST</span></div>');

            $.ajax({data: $('form[name="forgot_password_form"]').serialize(), success: function (data) {
                $('.loading').remove();
                if (data.status == 'ok') {
                    $('form[name="forgot_password_form"] .form-submitted').removeClass('hidden');
                    $('form[name="forgot_password_form"] input:text').data('email',$('form[name="forgot_password_form"] input:text').val());
                    $('form[name="forgot_password_form"] input:text').val('');
                    $('form[name="forgot_password_form"] input:text').closest('.row').hide();
                } else if (data.status == 'error') {
                    form_submit_response('form[name="forgot_password_form"]', 'error', data.error);
                } else {
                    form_submit_response('form[name="forgot_password_form"]', 'error', 'An unknown error occurred. Please try again later');
                }

            }});
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}

function resetPasswordForm() {
    $('form[name="reset_password_form"]').addClass('ready');
    $('form[name="reset_password_form"]').validate({
        rules: {
            password: {
                required: true,
                minlength: 6
            },
            password_verify: {
                minlength: 6,
                equalTo: "#password"
            },
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            'email': 'Required',
            'password': 'Required',
            'password_verify': 'Required'
        },
        submitHandler: function () {
            $('body').prepend('<div class="loading blocking text-center"><span>RESETTING PASSWORD</span></div>');

            $.ajax({data: $('form[name="reset_password_form"]').serialize(), success: function (data) {
                $('.loading').remove();
                if (data.status == 'ok') {
                    setTimeout(function(){
                        
                        window.location = URL;
                    },5000);
                    $('form[name="reset_password_form"] input:text').val('');
                    $('form[name="reset_password_form"] input:password').val('');
                    form_submit_response('form[name="reset_password_form"]', 'success', data.message);
                } else if (data.status == 'error') {
                    form_submit_response('form[name="reset_password_form"]', 'error', data.error);
                } else {
                    form_submit_response('form[name="reset_password_form"]', 'error', 'An unknown error occurred. Please try again later');
                }

            }});
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}


function form_submit_response(target, type, message, r) {
	"use strict";
    /*var redirect = r || false;
    $('#growls').empty();
    if (type == 'error') {
        $.growl.error({ duration:6000,title:'',message: message, location: 'tr',size:'large'  });
    } else if (type == 'warning') {
        $.growl.warning({ duration:6000,title:'',message: message, location: 'tr',size:'large'   });
    } else if (type == 'success') {
        $.growl.notice({ duration:6000,title: '', message: message, location: 'tr',size:'large'  });
    } else {
        $.growl({duration:6000,title: '', message: message, location: 'tr',size:'large'  });
    }
    if (redirect) {
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }*/
	alert(message);
}

$(function(){
	"use strict";
    $.validator.setDefaults({
        debug: false,
        errorPlacement: function(error) {
            error.hide();
        },
        highlight: function (element, errorClass, validClass) {

            if($(element).is(':radio')){
                $(element).parent().parent().find('.form-control-feedback').remove();
                $(element).parent().parent().append('<span class=" form-control-feedback" style="right:-20px"><i class="fa fa-times"></i></span>');
                $(element).parent().parent().addClass('has-error has-feedback');
            }else{
                $(element).addClass('invalid').removeClass('valid');
            }

        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('invalid').addClass('valid');
        }
    });
});
