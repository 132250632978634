function initAvailabilityForm() { 
	var $the_form = $('form[name="availability_form"]');
	var $container = $('#availability-list');
    $the_form.validate({
        submitHandler: function() {
            $('.loading-availability').removeClass('hidden');
             
            $("html, body").animate({ scrollTop: $container.offset().top - 300 }, 500);

           
            var data = $the_form.serialize();
            var object = {

                'success': function (response){
                    $('.loading-availability').addClass('hidden');
                  
            		$container.html(response.markup);
                  
                },
                'data' : data,                
            };
            $.ajax(object);
            return false;
        },  
 
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}